import React, { useContext } from 'react';
import { InputLabel } from '../../components/IndividualLite/UserDetailsForm';
import { Row } from 'reactstrap';
import { InputField, SelectField } from '../../../../../components';
import { ques1, ques2, ques3 } from '../constants';
import CheckBoxFieldMutiple from '../../../../../components/CheckBox/CheckBoxFieldMutiple';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup';
import _ from 'lodash';
import { DialogContext } from '../../../../../store/context/DialogContext';

export function validateName(name) {
    const nameRegex = /^[a-zA-Z\s]*$/;
    return nameRegex.test(name);
}

export const handleValidation = (field, value, data) => {
    const nameRegex = /^[a-zA-Z\s]*$/;

    if (['firstName', 'lastName'].includes(field)) {
        if (!value) {
            return `Please enter your ${_.capitalize(field.replace(/Name$/, ''))} Name`;
        }
        if (value?.length < 2 || value?.length > 50) {
            return `Your ${_.capitalize(field.replace(/Name$/, ''))} Name should be between 2 and 50 characters.`;
        }
        if (!nameRegex.test(value)) {
            return `Please enter a valid ${_.capitalize(field.replace(/Name$/, ''))} Name with only alphabetic characters.`;
        }
        return "";
    }

    if (field === 'businessOther') {
        if (data.businesses?.includes("Other") && !value) {
            return "Please specify the business type if 'Other' is selected.";
        }
        if (value?.length > 100) {
            return "The business description should be less than 100 characters.";
        }
        return "";
    }

    if (field === 'applicantType' && !value) {
        return "Please select an applicant type.";
    }

    if (field === 'businesses') {
        if (!value || value?.length === 0) {
            return "Please select at least one business type.";
        }
        return "";
    }

    if (field === 'sourceOfFunds') {
        if (!value || value?.length === 0) {
            return "Please select at least one source of funds.";
        }
        return "";
    }

    return !value ? `Please enter ${_.startCase(field)}` : "";
};

const Page1 = ({ asLabel, data, handleChange, pageNum, loading, step, handleSubmit }) => {
    const [hasError, setHasError] = React.useState(false);
    const { showError } = useContext(DialogContext);
    const handleNext = async () => {
        const requiredFields = ['firstName', 'lastName', 'applicantType', 'businesses', 'sourceOfFunds', 'businessOther'];

        let errors = [];

        requiredFields.forEach(field => {
            const fieldError = handleValidation(field, data[field], data);
            if (fieldError) {
                errors.push(fieldError);
            }
        });

        if (errors.length > 0) {
            setHasError(true);
            showError(errors[0]);
            return;
        }

        setHasError(false);
        await handleSubmit(false);
    };

    return (
        <>
            <div className='mb-3'>
                <p className='pt-2'>
                    This form is for Business Plus Customers to upgrade their account from Business Plus to Business Prime. Business Prime is for accountholders with a need for higher limits or for accounts involving third party funds such as an MSB, Non Bank Financial Services, VASPS, Exchanges and OTC's.
                </p>

                <InputLabel label="Authorized person completing this application" labelStyle={{ fontSize: 15 }} />
                <Row>
                    <InputField
                        className="col-md-6"
                        placeholder={"First"}
                        value={data?.firstName}
                        onChange={(v) => handleChange("firstName", v)}
                        error={handleValidation('firstName', data?.firstName)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                        regex={/^[a-zA-Z\s]*$/}
                    />
                    <InputField
                        className="col-md-6"
                        placeholder={"Last"}
                        value={data?.lastName}
                        onChange={(v) => handleChange("lastName", v)}
                        error={handleValidation('lastName', data?.lastName)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                        regex={/^[a-zA-Z\s]*$/}
                    />
                </Row>

                <SelectField
                    labelStyle={{ fontSize: 15 }}
                    label="As the person completing this application, I am a"
                    value={data?.applicantType}
                    onChange={(v) => handleChange('applicantType', v.value)}
                    data={ques1}
                    placeholder="Select Applicant Type"
                    error={handleValidation('applicantType', data?.applicantType)}
                    showErr={hasError}
                    isDisabled={asLabel}
                />

                <p className='pt-3'>
                    For the purposes of this application, FV Bank considers all of the following to be Money Services Businesses, whether a license is required or not in your jurisdictions. (Exchanges, OTC Providers, Liquidity Providers, Wallet Providers, Non Bank Financial Institutions, VASPS, DLT's EMI's, PSD license holders, Brokers, Remittance Provides and FX Providers.
                </p>

                <CheckBoxFieldMutiple
                    className="my-1 col-sm-12 d-flex"
                    labelStyle={{ fontSize: 15 }}
                    label="Your business is which of the following?"
                    data={ques2}
                    placeholder="Select"
                    value={data.businesses ? data.businesses : []}
                    onChange={(v) => handleChange('businesses', v)}
                    error={handleValidation('businesses', data.businesses)}
                    showErr={hasError}
                    isDisabled={false}
                    required
                />
                {!!data.businesses?.includes("Other") ? (
                    <InputField
                        className="m"
                        label="If Others, please specify"
                        labelStyle={{ fontSize: 15 }}
                        placeholder=""
                        value={data?.businessOther}
                        onChange={(v) => { handleChange("businessOther", v) }}
                        error={handleValidation('businessOther', data?.businessOther, data)}
                        showErr={hasError}
                        max={100}
                        type="text"
                        isDisabled={asLabel}
                    />
                ) : null}

                <CheckBoxFieldMutiple
                    className="my-1 col-sm-12 d-flex"
                    labelStyle={{ fontSize: 15 }}
                    label="Who's funds will interact with the account."
                    data={ques3}
                    placeholder="Select"
                    value={data.sourceOfFunds ? data.sourceOfFunds : []}
                    onChange={(v) => handleChange('sourceOfFunds', v)}
                    error={handleValidation('sourceOfFunds', data.sourceOfFunds)}
                    showErr={hasError}
                    isDisabled={false}
                    required
                />
            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel}
                limit={10}
                onNextClick={handleNext}
                step={step}
                onBackClick={() => { }}
            />
        </>
    )
}

export default Page1;
